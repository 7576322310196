/* You can add global styles to this file, and also import other style files */

a {
    color: #1a63b1;
    font-weight: 550;
}

app-emprestimo-negado .p-dialog-titlebar.p-widget-header {
    background-color: lightgrey !important;
}

.btn {
    border-radius: 5px !important;
}

body,
html {
    background: #eeeeee !important;
    background: radial-gradient(circle farthest-side at center, #ffffff, #eeeeee) !important;
    font-size: 16px;
    color: #5b5b5f;
    font-family: Arial;
    font-size: 16px;
    height: 100% !important;
}

body.no-scroll {
    /*overflow: hidden;*/
}

.card {
    margin-bottom: 1em;
}

div.dropdown-menu.dropdown-menu-right.text-right.show {
    z-index: 2000;
}

h4 {
    font-weight: bold !important;
}

label {
    font-weight: 500 !important;
}

nav {
    padding: 0 !important;
}

.no-wrap {
    white-space: nowrap;
}

p-slider {
    display: block !important;
    margin-bottom: 1em !important;
}

.primeng-sidebar .p-sidebar-right {
    width: auto !important;
}

#side-menu svg {
    fill: #1a63b1;
    max-height: 1.5em;
    vertical-align: middle;
}

.text-large {
    font-size: 1.5rem;
    line-height: 100%;
}

.p-dialog .p-dialog {
    max-width: 600px !important;
}

.p-dialog-footer .p-dialog-content {
    border: none !important;
}

.p-dialog .p-dialog-header {
    background-color: #1b6394 !important;
    border-top-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    text-align: center !important;
    display: block;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #ffffff !important;
    position: absolute !important;
    right: 2px !important;
    top: 2px !important;
}

.p-slider .p-slider-handle {
    background-color: #0275d8 !important;
    border: none !important;
    cursor: pointer !important;
}

.saldo-disponivel {
    margin-top: 0;
    margin-bottom: 0.1em;
    padding-top: 0.5em;
}

.hr-linha {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

/* PrimeNG Styling */
.ui-chkbox-label {
    display: inline !important;
    margin-bottom: 0 !important;
}

.steps-content .ui-steps-item {
    width: 20%;
}

.actions .nextQuestion,
.saldamento .nextQuestion {

    background-color: #729118;
    border-color: #729118;
}

.actions .nextQuestion:enabled:hover {
    background-color: #667f1b;
    border-color: #667f1b;
}

body .ui-button.frm-avancar-adesao {
    font-size: 20px;
}

.saldamento .p-button:not(.nextQuestion) {
    border-color: #1b6394;
    background-color: #1b6394;
}

.saldamento .p-button {
    /* Safari 3-4, iOS 1-3.2, Android 1.6- */
    -webkit-border-radius: 15px;
    /* Firefox 1-3.6 */
    -moz-border-radius: 15px;
    /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
    border-radius: 15px;
    padding: 10px 35px;
    margin: 10px;
    font-size: 18px;
}

.steps-content .p-steps .p-steps-item .p-menuitem-link {
    background-color: transparent;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .steps-content .p-steps .p-steps-item.p-disabled {
        font-size: 0.8em;
    }
}

@media screen and (max-width: 768px) {
    .steps-content .p-steps .p-steps-item.p-disabled {
        display: none;
    }
}

.p-field-checkbox {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.p-field-radiobutton {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    & label {
        margin-bottom: 0;
        margin-left: 5px;
    }
}
